<template lang="">
    <div class="container mx-auto text-black flex px-4 md:px-0 py-4 justify-between">
        <div class="flex pl-2 pr-2 pt-1 pb-1 rounded-full bg-gray-300 text-gray-700 active:bg-gray-300 justify-center items-center text-xs" @click="qrCodeReaderShown = !qrCodeReaderShown">qr code</div>
        <a @click="$router.go(-1)">
            <div class=" hover:bg-gray-500 rounded-full p-2" @click="menu_open = !menu_open">
                <img src="https://img.icons8.com/?size=512&id=8112&format=png" class="h-6"/>
            </div>
        </a>

    </div>
    <div class="container mx-auto text-black my-auto mx-auto p-4 pb-24 md:p-0">
        <div class="py-4 flex justify-center text-2xl text-gray-700">
            Добавить Расход
        </div>
        <div class="py-2 mb-4 flex flex-col items-center">
            <div class="flex flex-row items-center justify-center border-2 rounded-full px-4 bg-white md:w-1/3 w-2/3">
                <label class="mt-2 px-2 pb-1 font-semibold">KZT</label>
                <input v-model="expense.amount" type="number" class="outline-none p-2 text-4xl w-2/3 rounded-md text-right" placeholder="00000" min="0" max="99999"/>
            </div>
        </div>
        <div class="py-2 px-4 flex flex-row bg-white items-center relative rounded-md ">
            <label class="px-2">
                <img src="https://img.icons8.com/?size=512&id=13705&format=png" class="h-6 w-6"/>
            </label>
            <div v-if="is_tags_loaded" class="w-full">
                <select  v-model="expense.main_tag.id" class="py-2 px-3 w-full bg-white">
                    <option value="0" disabled>Категория</option>
                    <option v-for="tag in tags" v-bind:key="tag.id" :value="tag.id">{{ tag.emoji + ' ' + tag.title }}</option>
                    <option value="-1">Создать новую</option>
                </select>
            </div>
            <div v-else class="py-1 px-3 h-9">
                <i>Загрузка...</i>
            </div>
        </div>
        <div v-if="is_tags_loaded" class="flex flex-row overflow-x-hidden w-full gap-2 text-xs py-4 mb-4">
            <div v-for="tag in popular_tags" :key="tag.id" @click="expense.main_tag.id = tag.id" class="px-4 py-2 rounded-full border whitespace-none" :class="[ expense.main_tag.id == tag.id ? 'bg-green-100' : 'bg-white']">{{ tag.emoji }}</div>
        </div>
        {{ expense.main_tag.id }}
        <div v-if="expense.main_tag.id == -1" class="py-2 px-4 mb-4 flex flex-row bg-white items-center relative rounded-md ">
            <label class="px-2">
                <img src="https://img.icons8.com/?size=512&id=1501&format=png" class="h-6 w-6"/>
            </label>
            <input v-model="expense.main_tag.title"  class="rounded-md py-2 px-3 w-4/6" placeholder="Название новой категории"/>
            <input v-model="expense.main_tag.emoji"  class="rounded-md py-2 px-3 w-2/6" placeholder="🤍"/>
        </div>
        <div class="py-2 px-4 mb-4 flex flex-row bg-white items-center relative rounded-md ">
            <label class="px-2">
                <img src="https://img.icons8.com/?size=512&id=TZQlTUfG7rbB&format=png" class="h-6 w-6"/>
            </label>
            <input v-model="expense.description" class="rounded-md py-2 px-3 w-full" placeholder="Описание" />
        </div>
        <div class="py-2 px-4 mb-4 flex flex-row bg-white items-center relative rounded-md ">
            <label class="px-2">
                <img src="https://img.icons8.com/?size=512&id=23&format=png" class="h-6 w-6"/>
            </label>
            <input v-model="expense.date" class="rounded-md py-2 px-3 w-full bg-white"  placeholder="Сегодня" type="date"/>
        </div>
        <div class="py-2  flex justify-center w-full">
            <input @click="saveExpense(false)" type="submit" class="cursor-pointer rounded-lg py-4 px-6 w-full bg-green-500 font-semibold text-white uppercase" value="Сохранить"/>
        </div>
        <div class="py-2  flex justify-center w-full">
            <input @click="saveExpense(true)" type="submit" class="cursor-pointer rounded-lg py-2 px-6 bg-gray-300 text-white" value="сохранить и добавить еще"/>
        </div>
        <div v-if="qrCodeReaderShown" class="w-full h-full fixed top-0 left-0 bg-gray-100">
            <div class="absolute top-10 right-10 z-10">
                <div class="h-12 w-12 border rounded-md flex flex-row items-center justify-center text-white" @click="qrCodeReaderShown = false">X</div>
            </div>
            <qrcode-stream @detect="qrOnDetect" @error="qrCodeOnError" class="w-full h-full"></qrcode-stream>
        </div>
    </div>
</template>
<script>
// import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
    components: {
        QrcodeStream
    },
    data () {
        return {
            expense: {
                date: null,
                amount: null,
                main_tag: {
                    id: 0,
                    title: null,
                    emoji: null
                },
                description: null
            },
            tags: [],
            is_tags_loaded: false,

            qrCodeReaderShown: false,
            qrCodesFound: []
        }
    },
    methods: {
        saveExpense (stay) {
            if (this.expense.amount === null) return alert('Пожалуйста, заполните сумму')
            if (this.expense.main_tag.id === 0) return alert('Пожалуйста, выберите категорию')
            const response = this.postServerData('store_expense', { data: this.expense })
            response.then((response) => (console.log(response)))
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => {
                        if (stay) {
                            this.expense.amount = null
                            this.expense.main_tag = { id: 0, title: null, emoji: null }
                            this.expense.description = null
                        } else this.$router.push({ name: 'expenses_list' })
                    })
        },
        getTags () {
            const response = this.getServerData('get_expenses_tags')
            response.then((response) => (this.tags = response.data.data))
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => {
                        this.is_tags_loaded = true
                    })
        },
        qrOnDetect (detectedCodes) {
            this.qrCodeReaderShown = false
            const code = detectedCodes[0]
            if (code.format === 'qr_code') {
                const rawData = code.rawValue
                const urlParams = new URLSearchParams(rawData)
                const f = urlParams.get('f')
                const s = urlParams.get('s')
                this.expense.amount = parseInt(s)
                const t = urlParams.get('t')
                const date = `${t.slice(0, 4)}-${t.slice(4, 6)}-${t.slice(6, 8)}`
                this.expense.date = date
                // http://consumer.oofd.kz?i=423859561&f=010103400540&s=6052.00&t=20250315T135438

                const food = ['010103400540']
                if (food.includes(f)) {
                    this.expense.main_tag.id = 8
                    this.expense.main_tag.title = 'Продукты'
                    this.expense.main_tag.emoji = '🍞'
                }
                // const food = ['010103400540']
            }
            // this.amount =
        },
        qrCodeOnError (error) {
            if (error.name === 'NotAllowedError') {
            // user denied camera access permission
            } else if (error.name === 'NotFoundError') {
            // no suitable camera device installed
            } else if (error.name === 'NotSupportedError') {
            // page is not served over HTTPS (or localhost)
            } else if (error.name === 'NotReadableError') {
            // maybe camera is already in use
            } else if (error.name === 'OverconstrainedError') {
            // did you request the front camera although there is none?
            } else if (error.name === 'StreamApiNotSupportedError') {
            // browser seems to be lacking features
            }
        }
    },
    computed: {
        popular_tags () {
            return this.tags.slice(0, 7)
        }
    },
    mounted () {
        this.getTags()

        const now = new Date()
        const cdate = new Date(now.getTime() + 360 * 60000).toJSON().slice(0, 10)
        this.expense.date = cdate
    }
}
</script>
<style scoped></style>
